import * as React from "react";
import { forwardRef } from "react";
import { Button, SectionWrapper } from "~src/components";
// @ts-ignore
import heroBg from "../../assets/hero-bg.svg";
import { AvailabilityPulse } from "../AvailabilityPulse";

type HeroSectionProps = {
  id?: string;
  scrollToSection?: () => void;
};

export const HeroSection = forwardRef<HTMLDivElement, HeroSectionProps>(
  (props, ref) => {
    return (
      <SectionWrapper ref={ref} id={props.id}>
        <div className="relative isolate px-6 pt-8 lg:px-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 transform-gpu overflow-hidden blur-3xl -z-10"
          >
            <img src={heroBg} alt="" className="w-full h-auto object-contain" />
          </div>
          <div className="mx-auto max-w-2xl py-28 sm:py-32">
            <div className="text-center">
              <h1 className="text-balance text-4xl font-bold tracking-tight text-primary-900 sm:text-6xl">
                <span className="text-green-600">AI development</span> but
                simpler.
              </h1>
              <p className="mt-6 text-lg leading-8 text-primary-600">
                Afi Technologies specializes in developing custom AI agents
                tailored to your business needs, leveraging cutting-edge
                technologies from OpenAI, Anthropic, and LangChain.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Button
                  size="xlarge"
                  variant="primary"
                  onClick={props.scrollToSection}
                >
                  See Plans
                </Button>
              </div>
              <AvailabilityPulse />
            </div>
          </div>
        </div>
      </SectionWrapper>
    );
  }
);

HeroSection.displayName = "HeroSection";
