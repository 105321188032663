import * as React from "react";
import { forwardRef } from "react";
import { SectionWrapper } from "~src/components";
import { VisualStepper } from "../VisualStepper";
import { howItWorksStepsList } from "../../utils/defaultStepLists";

type HowItWorksSectionProps = {
  id?: string;
};

export const HowItWorksSection = forwardRef<
  HTMLDivElement,
  HowItWorksSectionProps
>((props, ref) => {
  return (
    <SectionWrapper ref={ref} id={props.id}>
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-green-600">
          How It Works
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-primary-900 sm:text-4xl">
          AI-Powered Solutions, Made Simple
        </p>
        <p className="mt-6 text-lg leading-8 text-primary-600">
          We take the complexity out of AI development. Whether you need custom
          AI agents, seamless integrations, or advanced automation, our process
          is simple: choose a plan, submit your requests, and we’ll handle the
          rest. With transparent pricing and expert execution, you can focus on
          scaling your business while we build the AI solutions that drive
          results.
        </p>
      </div>
      <VisualStepper steps={howItWorksStepsList} />
    </SectionWrapper>
  );
});

HowItWorksSection.displayName = "HowItWorksSection";
