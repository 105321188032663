import * as React from "react";
import { forwardRef } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Button, SectionWrapper } from "~src/components";
import { AvailabilityPulse } from "../AvailabilityPulse";

const tiers = [
  {
    name: "Starter Plan",
    id: "tier-starter",
    href: "https://buy.stripe.com/6oE3fxftd2je5gYeUY",
    price: { monthly: "$2,499", annually: "$15,000" },
    description: "Ideal for businesses beginning their AI automation journey:",
    features: [
      "Unlimited AI Development Requests",
      "One Active Task at a Time",
      "Standard Turnaround Time (4-6 Business Days)",
      "Asynchronous Communication",
      "Basic AI Agent Development",
      "LLM Integration (OpenAI, Anthropic, Hugging Face)",
      "Responsive AI Model Deployment",
      "Bug Fixes and Minor Enhancements",
      "No Long-Term Commitment",
    ],
  },
  {
    name: "Professional Plan",
    id: "tier-professional",
    href: "https://buy.stripe.com/fZe2bt5SD0b69xe5kp",
    price: { monthly: "$5,499", annually: "$39,000" },
    description: "All features from the Starter Plan, plus:",
    features: [
      "Two Active Tasks at a Time",
      "Faster Turnaround Time (2-4 Business Days)",
      "Priority Support and Implementation",
      "Monthly AI Strategy Sessions",
      "Advanced AI Model Fine-Tuning",
      "Performance Optimization for AI Agents",
      "Custom Feature Development (LangChain, Pinecone)",
      "Dedicated Communication Channel",
    ],
  },
  {
    name: "Enterprise Plan",
    id: "tier-enterprise",
    href: "https://buy.stripe.com/5kA17p6WHaPK8ta8wz",
    price: { monthly: "$7,999", annually: "$79,000" },
    description: "All features from the Professional Plan, plus:",
    features: [
      "Multiple Active Tasks",
      "Fastest Turnaround Time (1-3 Business Days)",
      "Weekly AI Strategy & Planning Sessions",
      "Custom AI Solutions and Tailored Development",
      "Extended AI Model Customization",
      "Comprehensive Testing and Quality Assurance",
      "DevOps Assistance for AI Deployments",
      "Priority Feature Implementation & Scaling",
      "Code Review and AI Consultation",
      "Comprehensive AI Documentation",
      "Non-Disclosure Agreement (NDA) Available",
    ],
  },
];

type PricingSectionProps = {
  id?: string;
};

export const PricingSection = forwardRef<HTMLDivElement, PricingSectionProps>(
  (props, ref) => {
    return (
      <SectionWrapper paddingY="small" ref={ref} id={props.id}>
        <div className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl">
            <div className="mx-auto max-w-4xl sm:text-center">
              <h2 className="text-base font-semibold leading-7 text-green-600">
                Membership Tiers
              </h2>
              <p className="mt-2 text-4xl font-bold tracking-tight text-primary-900 sm:text-5xl">
                Choose a plan that fits your needs
              </p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-primary-600 sm:text-center">
              We offer three membership tiers to fit your needs. Each plan comes
              with a set of features and benefits to help you get started.
            </p>
            <AvailabilityPulse />
            <div className="mt-20 flow-root">
              <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
                {tiers.map((tier) => (
                  <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 text-primary-900"
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-6 flex items-baseline gap-x-1">
                      <span className="text-5xl font-bold tracking-tight text-primary-900">
                        {tier.price.monthly}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-primary-600">
                        /mo
                      </span>
                    </p>

                    <Button
                      variant="primary"
                      size="large"
                      className="w-full mt-8"
                      onClick={() => {
                        window.open(tier.href, "_blank");
                      }}
                    >
                      Get Started
                    </Button>
                    <div className="flex justify-between items-center space-x-2 mt-6">
                      <hr className="border border-b-gray-300 w-1/4" />
                      <a
                        className="w-2/4 text-center text-green-600 hover:text-green-800"
                        href="https://calendly.com/afitechfreelance-io/afi-technologies-intro"
                        target="_blank"
                      >
                        or book a call
                      </a>
                      <hr className="border border-b-gray-300 w-1/4" />
                    </div>
                    <p className="mt-10 text-sm font-semibold leading-6 text-primary-900">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-6 space-y-3 text-sm leading-6 text-primary-600"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckCircleIcon
                            aria-hidden="true"
                            className="h-6 w-5 flex-none text-green-600"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
    );
  }
);

PricingSection.displayName = "PricingSection";
