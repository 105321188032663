export const faqQuestions = [
  {
    title: "What happens in my first week?",
    description: [
      "You'll receive an onboarding email with instructions on submitting AI development requests and sharing relevant assets. We’ll also set up an initial AI model or basic agent framework so you can start testing early. You’ll get access to a development preview link.",
      "For AI-powered apps, we ensure seamless integration with platforms like OpenAI, Anthropic, and LangChain.",
    ],
  },
  {
    title: "Can I really submit unlimited AI requests?",
    description: [
      "Yes! You can submit as many AI development requests as you need. However, based on your plan, we can only work on a set number of tasks at a time.",
      "For complex AI features, we break them into smaller, manageable tasks to ensure high-quality results.",
    ],
  },
  {
    title: "What kind of AI development can I request?",
    description: [
      "We specialize in AI-powered applications, including AI agent development, large language model (LLM) integrations, automation workflows, and AI-enhanced customer support tools.",
      "We also handle backend AI infrastructure using Node.js, Python, and vector search technologies like Pinecone.",
    ],
  },
  {
    title: "How long does it take to complete a request?",
    description: [
      "Turnaround times range from 1-7+ business days, depending on your plan and the complexity of the AI feature you're requesting.",
    ],
  },
  {
    title: "Why not just hire a full-time AI developer?",
    description: [
      "Hiring a senior AI engineer can cost over $150,000 per year, plus benefits, stock options, and additional costs. You might not need AI development full-time.",
      "With our subscription model, you only pay for what you need and can pause anytime.",
    ],
  },
  {
    title: "How does the pause feature work?",
    description: [
      "If you don’t have active AI development requests, you can pause your subscription and resume when you're ready. Work will be paused too.",
      "Your billing cycle is 31 days. If you use 11 days and pause, you’ll have 20 unused days to use whenever you need.",
    ],
  },
  {
    title: "What if I’m not happy with the AI model or solution?",
    description: [
      "No worries! We’ll keep refining and fine-tuning the AI model or automation until you're completely satisfied.",
    ],
  },
  {
    title: "What if I need an AI feature done urgently?",
    description: [
      "If you have a high-priority AI request, simply place it at the top of your list, and we’ll prioritize it for the fastest turnaround possible.",
    ],
  },
  {
    title: "What types of AI-powered apps can you build?",
    description: [
      "We develop AI-powered chatbots, automated assistants, predictive analytics tools, AI-driven recommendation systems, and more. Whether you need AI for customer service, automation, or decision-making, we've got you covered.",
    ],
  },
  {
    title: "Can I get a refund if I’m not satisfied?",
    description: [
      "Due to the custom nature of AI development, we don’t offer refunds, but we’ll work closely with you to ensure the AI solution meets your expectations.",
    ],
  },
];
