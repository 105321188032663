import * as React from "react";
import { forwardRef } from "react";
import {
  ArrowPathIcon,
  SwatchIcon,
  DevicePhoneMobileIcon,
  ServerStackIcon,
  ChatBubbleLeftRightIcon,
  ShieldCheckIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { SectionWrapper } from "~src/components";

type FeatureSectionProps = {
  id?: string;
};

const services = [
  {
    title: "Engaging UIs with React, TypeScript & Tailwind",
    icon: <SwatchIcon className="h-8 w-8 text-green-600" />,
    challenge: "Creating modern, user-friendly interfaces can be tricky.",
    solution:
      "We design fast, interactive UIs that look great and keep users engaged.",
  },
  {
    title: "Seamless Mobile-Friendly Design",
    icon: <DevicePhoneMobileIcon className="h-8 w-8 text-green-600" />,
    challenge: "Your website should work flawlessly on any device.",
    solution:
      "We build responsive designs that adjust perfectly to all screen sizes.",
  },
  {
    title: "Reliable Backends with Node.js & MongoDB",
    icon: <ServerStackIcon className="h-8 w-8 text-green-600" />,
    challenge: "A weak backend can slow down your app and frustrate users.",
    solution:
      "We create solid APIs and databases to keep everything running smoothly.",
  },
  {
    title: "Smart Strategy & AI Consulting",
    icon: <ChatBubbleLeftRightIcon className="h-8 w-8 text-green-600" />,
    challenge: "Making the right business decisions can be overwhelming.",
    solution:
      "We help you leverage AI and data-driven strategies for smarter growth.",
  },
  {
    title: "Performance & Security You Can Trust",
    icon: <ShieldCheckIcon className="h-8 w-8 text-green-600" />,
    challenge: "Keeping your site fast and secure takes ongoing effort.",
    solution:
      "We handle updates, security, and optimizations to keep your site running smoothly.",
  },
  {
    title: "Intuitive UX & Brand-Aligned Design",
    icon: <HandThumbUpIcon className="h-8 w-8 text-green-600" />,
    challenge: "A confusing design can drive users away.",
    solution:
      "We craft user-friendly experiences that align with your brand and keep users happy.",
  },
];

export const FeatureSection = forwardRef<HTMLDivElement, FeatureSectionProps>(
  (props, ref) => {
    return (
      <SectionWrapper ref={ref} id={props.id}>
        <div className="mx-auto lg:max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-green-600">
            Our services are unbeatable
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-primary-900 sm:text-4xl">
            Our services include AI development, integration, and support.
          </p>
          <p className="mt-6 text-lg leading-8 text-primary-600">
            Ever had an app idea but didn't know where to start? Worried about
            needing a large capital for development work? We've got you covered.
          </p>
        </div>
        <div className="relative mt-8 grid md:grid-cols-1 lg:grid-cols-2 gap-8">
          {services.map((service, idx) => (
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="flex items-left mb-4">
                <div className="mr-4">{service.icon}</div>
                <h3 className="text-xl font-bold text-gray-800">
                  {service.title}
                </h3>
              </div>

              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-semibold text-gray-500 mb-2">
                    The Challenge
                  </h4>
                  <p className="text-gray-600">{service.challenge}</p>
                </div>

                <div>
                  <h4 className="text-sm font-semibold text-primary-800 mb-2">
                    Our Solution
                  </h4>
                  <p className="text-gray-600">{service.solution}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SectionWrapper>
    );
  }
);

FeatureSection.displayName = "FeatureSection";
